import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './Components/Sass/main.scss'
import Home from './Components/Pages/Home';
import PrivacyPolicy from './Components/Pages/PrivacyPolicy'
import Axcelto20 from './Components/Pages/Axcelto20';
import Axcelto25 from './Components/Pages/Axcelto25';


function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/products/axcelto-20" element={<Axcelto20 />} />
          <Route path="/products/axcelto-2.5" element={<Axcelto25 />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
